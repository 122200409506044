<template>
  <TalpaLoaderWrapper :transparent="true" v-if="loading" />
  <ErrorAtom v-else-if="error" :error="error" />
  <ProductTierLevelsMolecule v-else>
    <ProductTierLevelMolecule
      v-for="tierLevel in tierLevels"
      :key="tierLevel.id"
      :productType="productType"
      :tierLevel="tierLevel"
      :availableLanguages="availableLanguages"
      :activeLanguageId="activeLanguageId"
      :hasSave="canSaveTierLevels"
      :canRemove="!!tierLevel.isNew"
      :addablePermissions="addablePermissions"
      :addableAssetDimensions="addableAssetDimensions"
      :addableWidgetTypes="addableWidgetTypes"
      :addableBundleRelations="addableBundleRelations"
      :addableBundleRelationsLoading="addableBundleRelationsLoading"
      @set-active-language-id="$emit('set-active-language-id', $event)"
      @set-translated-text="$emit('set-translated-text', $event, tierLevel.id)"
      @save-translated-text="$emit('save-translated-text', $event, tierLevel.id)"
      @restore-translated-text="$emit('restore-translated-text', $event, tierLevel.id)"
      @save-all-translated-texts="$emit('save-all-translated-texts', tierLevel.id)"
      @restore-all-translated-texts="$emit('restore-all-translated-texts', tierLevel.id)"
      @add-relation="$emit('add-relation', $event, tierLevel.id)"
      @remove-relation="$emit('remove-relation', $event, tierLevel.id)"
      @remove-tier-level="$emit('remove-tier-level', tierLevel.id)"
      @save-tier-level="$emit('save-tier-level', tierLevel.id)"
      @add-bundle-relation="$emit('add-bundle-relation', $event, tierLevel.id)"
      @remove-bundle-relation="$emit('remove-bundle-relation', $event, tierLevel.id)"
      @bundle-search-change="$emit('bundle-search-change', $event, tierLevel.id)"
      @bundle-dropdown-opened="$emit('bundle-dropdown-opened', tierLevel.id)"
      @bundle-dropdown-closed="$emit('bundle-dropdown-closed', tierLevel.id)"
    />
    <div class="actions">
      <IconButtonAtom :disabled="!canAddNewTierLevel" @click="$emit('add-new-tier-level')">
        <PlusIcon size="16" />
        <span>add tier level</span>
      </IconButtonAtom>
    </div>
  </ProductTierLevelsMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'
import { PlusIcon } from 'vue-feather-icons'

import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'
import ProductTierLevelMolecule from './ProductTierLevelMolecule'

const ProductTierLevelsMolecule = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  > .actions {
    padding: 1rem;
  }

  > div {
    border-bottom: 2px solid ${({ theme }) => theme.colors.archonBlack};

    &:last-child {
      border-bottom: unset;
    }
  }
`

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Error,
    },
    productType: {
      type: String,
    },
    tierLevels: {
      type: Array,
      required: true,
    },
    availableLanguages: {
      type: Array,
      required: true,
    },
    activeLanguageId: {
      type: String,
      required: true,
    },
    canAddNewTierLevel: {
      type: Boolean,
      required: true,
    },
    canSaveTierLevels: {
      type: Boolean,
      default: true,
    },
    addablePermissions: {
      type: Array,
      required: true,
    },
    addableAssetDimensions: {
      type: Array,
      required: true,
    },
    addableWidgetTypes: {
      type: Array,
      required: true,
    },
    addableBundleRelations: {
      type: Array,
      required: true,
    },
    addableBundleRelationsLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    ProductTierLevelsMolecule,
    ProductTierLevelMolecule,
    IconButtonAtom,
    PlusIcon,
  },
}
</script>
