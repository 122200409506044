<template>
  <ExpandableMolecule>
    <header :class="{ 'has-header': hasHeaderSlot }">
      <TriggerStyled :class="{ 'is-expanded': isExpanded }" @click="$emit('toggle-is-expanded')">
        <ChevronRightIcon />
        <span class="title">{{ title }}</span>
      </TriggerStyled>
      <slot name="header" />
    </header>
    <template v-if="isExpanded">
      <slot />
    </template>
  </ExpandableMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { buttonReset } from '@styles/mixins'
import { ChevronRightIcon } from 'vue-feather-icons'

const ExpandableMolecule = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  > header {
    display: flex;
    gap: 1rem;
  }
  > main {
    margin-top: 1rem;
  }
`

const TriggerStyled = styled('button')`
  ${buttonReset}
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  .feather {
    transition: transform 0.25s;
  }
  &.is-expanded {
    .feather {
      transform: rotate(90deg);
    }
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ExpandableMolecule,
    TriggerStyled,
    ChevronRightIcon,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
  },
}
</script>
