<template>
  <EntityListMolecule>
    <LabelAtom slot="header" :label="title" />
    <component
      v-for="relation in relations"
      :is="itemComponent"
      :key="relation.id"
      :item="relation"
      :labelKey="itemLabelKey"
      @update="$emit('update', $event)"
      @remove="$emit('remove', relation)"
    />
    <template slot="footer">
      <Multiselect
        v-if="!hasFooterSlot"
        track-by="id"
        :value="relations"
        :label="itemLabelKey"
        :placeholder="placeholder"
        :options="addables"
        :searchable="true"
        :multiple="true"
        :loading="addablesLoading"
        :internal-search="internalSearchEnabled"
        :clear-on-select="false"
        :close-on-select="true"
        :show-no-results="true"
        :hide-selected="true"
        @search-change="$emit('search-change', $event)"
        @select="$emit('select', $event)"
        @open="handleOpen"
        @close="handleClose"
      >
        <div slot="tag" />
        <template slot="selection" slot-scope="props">
          <span v-if="!props.isOpen && props.values?.length > 0">Click to add more</span>
        </template>
      </Multiselect>
    </template>
  </EntityListMolecule>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { PlusCircleIcon } from 'vue-feather-icons'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import DefaultEntityListItemAtom from '@/components/Atomic/Atoms/DefaultEntityListItemAtom'
import EntityListMolecule from '@/components/Atomic/Molecules/EntityListMolecule'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    relations: {
      type: Array,
      required: true,
    },
    itemComponent: {
      type: Object,
      default: () => DefaultEntityListItemAtom,
    },
    itemLabelKey: {
      type: String,
    },
    addables: {
      type: Array,
      default: () => [],
    },
    addablesLoading: {
      type: Boolean,
      default: false,
    },
    internalSearchEnabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    EntityListMolecule,
    LabelAtom,
    Multiselect,
    PlusCircleIcon,
  },
  data() {
    return {
      dropdownOpen: false,
    }
  },
  computed: {
    placeholder() {
      return this.dropdownOpen ? 'Type to search' : 'Click to add more'
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
  methods: {
    handleOpen() {
      this.dropdownOpen = true
      this.$emit('dropdown-opened')
    },
    handleClose() {
      this.dropdownOpen = false
      this.$emit('dropdown-closed')
    },
  },
}
</script>
