var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductTierLevelMolecule', [_c('ExpandableMolecule', {
    attrs: {
      "title": _vm.title,
      "isExpanded": _vm.isExpanded
    },
    on: {
      "toggle-is-expanded": function toggleIsExpanded($event) {
        _vm.isExpanded = !_vm.isExpanded;
      }
    }
  }, [_c('main', {
    staticClass: "grid"
  }, [_c('TranslationEditorMolecule', {
    attrs: {
      "title": 'Description',
      "availableLanguages": _vm.availableLanguages,
      "activeLanguageId": _vm.activeLanguageId,
      "hasSave": _vm.tierLevel.hasSaveTranslatedTexts,
      "hasSaveAll": _vm.tierLevel.hasSaveTranslatedTexts,
      "canSaveAll": _vm.tierLevel.canSaveAllTranslatedTexts,
      "hasRestore": _vm.tierLevel.hasSaveTranslatedTexts,
      "hasRestoreAll": _vm.tierLevel.hasSaveTranslatedTexts,
      "canRestoreAll": _vm.tierLevel.canRestoreAllTranslatedTexts,
      "markdownEnabled": true,
      "translatedTexts": _vm.tierLevel.localTranslatedTexts
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        return _vm.$emit('set-active-language-id', $event);
      },
      "set-translated-text": function setTranslatedText($event) {
        return _vm.$emit('set-translated-text', $event);
      },
      "save-translated-text": function saveTranslatedText($event) {
        return _vm.$emit('save-translated-text', $event);
      },
      "restore-translated-text": function restoreTranslatedText($event) {
        return _vm.$emit('restore-translated-text', $event);
      },
      "save-all-translated-texts": function saveAllTranslatedTexts($event) {
        return _vm.$emit('save-all-translated-texts');
      },
      "restore-all-translated-texts": function restoreAllTranslatedTexts($event) {
        return _vm.$emit('restore-all-translated-texts');
      }
    }
  }), _c('div', {
    staticClass: "subgrid"
  }, [_c('RelationManagerListMolecule', {
    attrs: {
      "title": 'Permissions',
      "relations": _vm.tierLevel.permissions,
      "itemLabelKey": 'name',
      "addables": _vm.addablePermissions
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('add-relation', $event);
      },
      "remove": function remove($event) {
        return _vm.$emit('remove-relation', $event);
      }
    }
  }), _c('RelationManagerListMolecule', {
    attrs: {
      "title": 'AssetDimensions',
      "relations": _vm.tierLevel.assetDimensions,
      "itemLabelKey": 'name',
      "addables": _vm.addableAssetDimensions
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('add-relation', $event);
      },
      "remove": function remove($event) {
        return _vm.$emit('remove-relation', $event);
      }
    }
  }), _c('RelationManagerListMolecule', {
    attrs: {
      "title": 'WidgetTypes',
      "relations": _vm.tierLevel.widgetTypes,
      "itemLabelKey": 'name',
      "addables": _vm.addableWidgetTypes
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('add-relation', $event);
      },
      "remove": function remove($event) {
        return _vm.$emit('remove-relation', $event);
      }
    }
  })], 1), _c('div', {
    staticClass: "bundle-manager"
  }, [_c('RelationManagerListMolecule', {
    attrs: {
      "title": _vm.bundleManagerTitle,
      "relations": _vm.bundleRelations,
      "addables": _vm.addableBundleRelations,
      "itemLabelKey": 'label',
      "addablesLoading": _vm.addableBundleRelationsLoading,
      "internalSearchEnabled": false
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('add-bundle-relation', $event);
      },
      "remove": function remove($event) {
        return _vm.$emit('remove-bundle-relation', $event);
      },
      "search-change": function searchChange($event) {
        return _vm.$emit('bundle-search-change', $event);
      },
      "dropdown-opened": function dropdownOpened($event) {
        return _vm.$emit('bundle-dropdown-opened');
      },
      "dropdown-closed": function dropdownClosed($event) {
        return _vm.$emit('bundle-dropdown-closed');
      }
    }
  })], 1), _c('div', {
    staticClass: "actions"
  }, [_vm.tierLevel.isNew && _vm.hasSave ? _c('IconButtonAtom', {
    attrs: {
      "disabled": !_vm.tierLevel.canSave
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('save-tier-level');
      }
    }
  }, [_vm._v(" save tier level ")]) : _vm._e()], 1)], 1), _c('IconButtonAtom', {
    attrs: {
      "slot": "header",
      "disabled": !_vm.canRemove
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('remove-tier-level');
      }
    },
    slot: "header"
  }, [_c('XIcon', {
    attrs: {
      "size": "16"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }