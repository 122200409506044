var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('TalpaLoaderWrapper', {
    attrs: {
      "transparent": true
    }
  }) : _vm.error ? _c('ErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  }) : _c('ProductTierLevelsMolecule', [_vm._l(_vm.tierLevels, function (tierLevel) {
    return _c('ProductTierLevelMolecule', {
      key: tierLevel.id,
      attrs: {
        "productType": _vm.productType,
        "tierLevel": tierLevel,
        "availableLanguages": _vm.availableLanguages,
        "activeLanguageId": _vm.activeLanguageId,
        "hasSave": _vm.canSaveTierLevels,
        "canRemove": !!tierLevel.isNew,
        "addablePermissions": _vm.addablePermissions,
        "addableAssetDimensions": _vm.addableAssetDimensions,
        "addableWidgetTypes": _vm.addableWidgetTypes,
        "addableBundleRelations": _vm.addableBundleRelations,
        "addableBundleRelationsLoading": _vm.addableBundleRelationsLoading
      },
      on: {
        "set-active-language-id": function setActiveLanguageId($event) {
          return _vm.$emit('set-active-language-id', $event);
        },
        "set-translated-text": function setTranslatedText($event) {
          return _vm.$emit('set-translated-text', $event, tierLevel.id);
        },
        "save-translated-text": function saveTranslatedText($event) {
          return _vm.$emit('save-translated-text', $event, tierLevel.id);
        },
        "restore-translated-text": function restoreTranslatedText($event) {
          return _vm.$emit('restore-translated-text', $event, tierLevel.id);
        },
        "save-all-translated-texts": function saveAllTranslatedTexts($event) {
          return _vm.$emit('save-all-translated-texts', tierLevel.id);
        },
        "restore-all-translated-texts": function restoreAllTranslatedTexts($event) {
          return _vm.$emit('restore-all-translated-texts', tierLevel.id);
        },
        "add-relation": function addRelation($event) {
          return _vm.$emit('add-relation', $event, tierLevel.id);
        },
        "remove-relation": function removeRelation($event) {
          return _vm.$emit('remove-relation', $event, tierLevel.id);
        },
        "remove-tier-level": function removeTierLevel($event) {
          return _vm.$emit('remove-tier-level', tierLevel.id);
        },
        "save-tier-level": function saveTierLevel($event) {
          return _vm.$emit('save-tier-level', tierLevel.id);
        },
        "add-bundle-relation": function addBundleRelation($event) {
          return _vm.$emit('add-bundle-relation', $event, tierLevel.id);
        },
        "remove-bundle-relation": function removeBundleRelation($event) {
          return _vm.$emit('remove-bundle-relation', $event, tierLevel.id);
        },
        "bundle-search-change": function bundleSearchChange($event) {
          return _vm.$emit('bundle-search-change', $event, tierLevel.id);
        },
        "bundle-dropdown-opened": function bundleDropdownOpened($event) {
          return _vm.$emit('bundle-dropdown-opened', tierLevel.id);
        },
        "bundle-dropdown-closed": function bundleDropdownClosed($event) {
          return _vm.$emit('bundle-dropdown-closed', tierLevel.id);
        }
      }
    });
  }), _c('div', {
    staticClass: "actions"
  }, [_c('IconButtonAtom', {
    attrs: {
      "disabled": !_vm.canAddNewTierLevel
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-new-tier-level');
      }
    }
  }, [_c('PlusIcon', {
    attrs: {
      "size": "16"
    }
  }), _c('span', [_vm._v("add tier level")])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }