var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EntityListMolecule', [_c('LabelAtom', {
    attrs: {
      "slot": "header",
      "label": _vm.title
    },
    slot: "header"
  }), _vm._l(_vm.relations, function (relation) {
    return _c(_vm.itemComponent, {
      key: relation.id,
      tag: "component",
      attrs: {
        "item": relation,
        "labelKey": _vm.itemLabelKey
      },
      on: {
        "update": function update($event) {
          return _vm.$emit('update', $event);
        },
        "remove": function remove($event) {
          return _vm.$emit('remove', relation);
        }
      }
    });
  }), _c('template', {
    slot: "footer"
  }, [!_vm.hasFooterSlot ? _c('Multiselect', {
    attrs: {
      "track-by": "id",
      "value": _vm.relations,
      "label": _vm.itemLabelKey,
      "placeholder": _vm.placeholder,
      "options": _vm.addables,
      "searchable": true,
      "multiple": true,
      "loading": _vm.addablesLoading,
      "internal-search": _vm.internalSearchEnabled,
      "clear-on-select": false,
      "close-on-select": true,
      "show-no-results": true,
      "hide-selected": true
    },
    on: {
      "search-change": function searchChange($event) {
        return _vm.$emit('search-change', $event);
      },
      "select": function select($event) {
        return _vm.$emit('select', $event);
      },
      "open": _vm.handleOpen,
      "close": _vm.handleClose
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(props) {
        var _props$values;
        return [!props.isOpen && ((_props$values = props.values) === null || _props$values === void 0 ? void 0 : _props$values.length) > 0 ? _c('span', [_vm._v("Click to add more")]) : _vm._e()];
      }
    }], null, false, 730524686)
  }, [_c('div', {
    attrs: {
      "slot": "tag"
    },
    slot: "tag"
  })]) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }