var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ExpandableMolecule', [_c('header', {
    class: {
      'has-header': _vm.hasHeaderSlot
    }
  }, [_c('TriggerStyled', {
    class: {
      'is-expanded': _vm.isExpanded
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle-is-expanded');
      }
    }
  }, [_c('ChevronRightIcon'), _c('span', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))])], 1), _vm._t("header")], 2), _vm.isExpanded ? [_vm._t("default")] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }