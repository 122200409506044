<template>
  <ProductTierLevelMolecule>
    <ExpandableMolecule :title="title" :isExpanded="isExpanded" @toggle-is-expanded="isExpanded = !isExpanded">
      <main class="grid">
        <TranslationEditorMolecule
          :title="'Description'"
          :availableLanguages="availableLanguages"
          :activeLanguageId="activeLanguageId"
          :hasSave="tierLevel.hasSaveTranslatedTexts"
          :hasSaveAll="tierLevel.hasSaveTranslatedTexts"
          :canSaveAll="tierLevel.canSaveAllTranslatedTexts"
          :hasRestore="tierLevel.hasSaveTranslatedTexts"
          :hasRestoreAll="tierLevel.hasSaveTranslatedTexts"
          :canRestoreAll="tierLevel.canRestoreAllTranslatedTexts"
          :markdownEnabled="true"
          :translatedTexts="tierLevel.localTranslatedTexts"
          @set-active-language-id="$emit('set-active-language-id', $event)"
          @set-translated-text="$emit('set-translated-text', $event)"
          @save-translated-text="$emit('save-translated-text', $event)"
          @restore-translated-text="$emit('restore-translated-text', $event)"
          @save-all-translated-texts="$emit('save-all-translated-texts')"
          @restore-all-translated-texts="$emit('restore-all-translated-texts')"
        />
        <div class="subgrid">
          <RelationManagerListMolecule
            :title="'Permissions'"
            :relations="tierLevel.permissions"
            :itemLabelKey="'name'"
            :addables="addablePermissions"
            @select="$emit('add-relation', $event)"
            @remove="$emit('remove-relation', $event)"
          />
          <RelationManagerListMolecule
            :title="'AssetDimensions'"
            :relations="tierLevel.assetDimensions"
            :itemLabelKey="'name'"
            :addables="addableAssetDimensions"
            @select="$emit('add-relation', $event)"
            @remove="$emit('remove-relation', $event)"
          />
          <RelationManagerListMolecule
            :title="'WidgetTypes'"
            :relations="tierLevel.widgetTypes"
            :itemLabelKey="'name'"
            :addables="addableWidgetTypes"
            @select="$emit('add-relation', $event)"
            @remove="$emit('remove-relation', $event)"
          />
        </div>
        <div class="bundle-manager">
          <RelationManagerListMolecule
            :title="bundleManagerTitle"
            :relations="bundleRelations"
            :addables="addableBundleRelations"
            :itemLabelKey="'label'"
            :addablesLoading="addableBundleRelationsLoading"
            :internalSearchEnabled="false"
            @select="$emit('add-bundle-relation', $event)"
            @remove="$emit('remove-bundle-relation', $event)"
            @search-change="$emit('bundle-search-change', $event)"
            @dropdown-opened="$emit('bundle-dropdown-opened')"
            @dropdown-closed="$emit('bundle-dropdown-closed')"
          />
        </div>
        <div class="actions">
          <IconButtonAtom v-if="tierLevel.isNew && hasSave" :disabled="!tierLevel.canSave" @click="$emit('save-tier-level')">
            save tier level
          </IconButtonAtom>
        </div>
      </main>
      <IconButtonAtom slot="header" :disabled="!canRemove" @click="$emit('remove-tier-level')">
        <XIcon size="16" />
      </IconButtonAtom>
    </ExpandableMolecule>
  </ProductTierLevelMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { XIcon } from 'vue-feather-icons'

import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'
import ExpandableMolecule from '@/components/Atomic/Molecules/ExpandableMolecule.vue'

import TranslationEditorMolecule from './TranslationEditorMolecule'
import RelationManagerListMolecule from '@/components/Atomic/Molecules/RelationManagerListMolecule'

const ProductTierLevelMolecule = styled('div')`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  > .title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  main.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 1rem;
    .subgrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: min-content;
      grid-gap: 1rem;
    }
    .bundle-manager {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: min-content;
      grid-gap: 1rem;
    }
  }
`

export default {
  props: {
    productType: {
      type: String,
      required: true,
    },
    tierLevel: {
      type: Object,
      required: true,
    },
    availableLanguages: {
      type: Array,
      required: true,
    },
    activeLanguageId: {
      type: String,
      required: true,
    },
    hasSave: {
      type: Boolean,
      default: true,
    },
    canRemove: {
      type: Boolean,
      required: true,
    },
    addablePermissions: {
      type: Array,
      required: true,
    },
    addableAssetDimensions: {
      type: Array,
      required: true,
    },
    addableWidgetTypes: {
      type: Array,
      required: true,
    },
    addableBundleRelations: {
      type: Array,
      required: true,
    },
    addableBundleRelationsLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ProductTierLevelMolecule,
    ExpandableMolecule,
    TranslationEditorMolecule,
    RelationManagerListMolecule,
    IconButtonAtom,
    XIcon,
  },
  data() {
    return {
      isExpanded: this.tierLevel?.isNew ?? false,
    }
  },
  computed: {
    title() {
      return `Tier level ${this.tierLevel.tierLevel}`
    },
    bundleRelations() {
      if (this.productType === 'APP') {
        return this.tierLevel?.bundledBy.map(m => ({
          ...m,
          label: `${m.product?.name} - Tier level ${m.tierLevel}`,
        }))
      } else if (this.productType === 'BUNDLE') {
        return this.tierLevel?.bundles.map(m => ({
          ...m,
          label: `${m.product?.name} - Tier level ${m.tierLevel}`,
        }))
      } else {
        throw new Error(`unhandled bundleRelations for product type '${this.productType}'`)
      }
    },
    bundleManagerTitle() {
      if (this.productType === 'APP') {
        return 'Bundled by'
      } else if (this.productType === 'BUNDLE') {
        return 'Bundled products'
      } else {
        throw new Error(`unhandled bundleManagerTitle for product type '${this.productType}'`)
      }
    },
  },
}
</script>
